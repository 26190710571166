<template>
  <div class="main">
    <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
      <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".csv"
      />

      <label for="fileInput" class="file-label" v-if="files.length === 0">
        <div style=" font-size: 1rem;" v-if="isDragging">Release to drop file here.</div>
        <div class="file_uploader" v-else>
          <img class="mb-2" src="@/assets/upload_cloud.svg"/>
          <p class="mb-1">Upload a CSV</p>
          <span class="mb-2">Drag & drop or click to upload</span>
        </div>
      </label>

      <div :class="{'preview-container, mt-4' : files.length === 0}" v-if="files.length">
<!--        <div class="gro__select gro__input mb-4">-->
<!--          <label for="txt_height_units" class="gro__input__label text-left font-size-14">Select referral source *</label>-->
<!--          <select id="txt_height_units" v-model="referralSource"-->
<!--                  class="gro__select__native__element gro__input__element">-->
<!--            <option :value="null" label="Select from the list"></option>-->
<!--            <option v-for="(item,index) in services" :key="`item-${index}`" :value="item.id" :label="item.name"></option>-->
<!--          </select>-->
<!--        </div>-->

        <div v-for="file in files" :key="file.name" class="preview-card d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center">
            <img class="mr-2" src="@/assets/csv.png" width="30"/>
            <p :title="file.name">
              {{ file.name}}
            </p>
          </div>
          <div>
            <div class="ml-2 delete" @click="remove(files.indexOf(file))">
              <i class="icon material-icons">delete</i>
            </div>
          </div>
        </div>
      </div>

    </div>
    <gro-button :loading="uploadingFile" loadingTitle="Uploading..." :disabled="files.length < 1" gradient centered class="w-100 mt-3" @click="uploadFiles">
      Upload
    </gro-button>
  </div>
</template>

<script>
import referralClient from "@/helpers/referralClient";

export default {
  props: ['services'],
  data() {
    return {
      isDragging: false,
      files: [],
      referralSource: null,
      file: '',
      uploadingFile: false,
    }
  },
  methods: {
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },
    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
          incomingFiles.some(
              (file) => file.name === r.name && file.size === r.size
          )
      );
      if (fileExist) {
        self.showMessage = true;
        alert("New upload contains files that already exist");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    makeName(name) {
      return (
          name.split(".")[0].substring(0, 3) +
          "..." +
          name.split(".")[name.split(".").length - 1]
      );
    },
    remove(i) {
      setTimeout(() => {
        this.files.splice(i, 1);
      }, 1);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    uploadFiles() {
      const file = this.files;
      if (this.files.length === 0) {
        return;
      }
      this.uploadingFile = true;

      const formData = new FormData();

      formData.append("file", file[0]);

      referralClient.post('/Lead/import/csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      .then((res) => {
        console.log(res)
        this.uploadingFile = false;
        this.$emit('close');
      })
    },
    submitFile(){
      /*
              Initialize the form data
          */
      let formData = new FormData();

      /*
          Add the form data we need to submit
      */
      formData.append('file', this.file);

      /*
        Make the request to the POST /single-file URL
      */
      referralClient.post('/Lead/import/csv',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(){
        console.log('SUCCESS!!');
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
  },
};
</script>

<style lang="scss">
.main {
  text-align: center;
}
.dropzone-container {
  border-radius: 12px;
  border: dashed 1px #bfbfbf;
  padding: 10px;
  width: 100%;
  .file_uploader {
    span {
      color: #7a7a7a;
      font-size: 14px;
    }
    p {
      font-size: 1rem;
    }
  }
}
.delete {
  color: #ff394a;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-card {
  display: flex;
  padding: 5px;
  margin-left: 5px;
}
.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
</style>